import { Accordion, AccordionTab } from "primereact/accordion";
import { useEffect, useState } from 'react';
import { Breadcrumb, Card, Stack } from 'react-bootstrap';
import ThemeCustomDropdown from "../../../components/common/CustomDropdown";
import { SvgIcons } from '../../../components/common/SvgIcons';
import DataGridCommon from "../../../components/common/dataGrid/DataGridCommon";
import { HIDE_TABLE_PAGINATOR } from "../../../constants/global";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getApplicantOfClientBasedOnClientIDAction, getCaseOfClientBasedOnClientIDAction, getQuestionnairesListAction, questionairreActions } from "../../../redux/questionairreModule/questionairreSlice";
import RenderActionCustom from "../../../components/common/dataGrid/ActionCellCustom";
import { useNavigate } from "react-router-dom";
import appConfig from "../../../helpers/config";


const ListQuestionnaires = () => {
    const caseID = useSelector((state: any) => state.questionairreSlice.caseID);
    const navigate = useNavigate();
    const isLoading = useSelector((state: any) => state.questionairreSlice.loading);
    const [caseOptions, setCaseOptions] = useState<any>(caseID);
    const [applicantsOptions, setApplicantsOptions] = useState<any>('');
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [questionnaireData, setQuestionnaireData]: any = useState<any>([]);
    const [caseOptionsData, setCaseOptionsData]: any = useState<any>([]);
    const [applicantData, setApplicantData]: any = useState<any>([]);


    useEffect(() => {
        GetCasesForClient();
    }, []);

    useEffect(() => {
        if (!caseOptions) return;
        dispatch(questionairreActions.setCaseID({ caseID: caseOptions }));
        GetApplicantBasedOnCaseForClient(caseOptions);
        GetQuestionnaireList(applicantsOptions);
    }, [caseOptions]);

    useEffect(() => {
        if (!applicantsOptions) return;
        GetQuestionnaireList(applicantsOptions);
    }, [applicantsOptions]);

    const allIndexes = questionnaireData.map((item:any, index:any) => index);

    const GetQuestionnaireList = (applicantsOptions: any) => {
        const payload = {
            case_id: caseOptions,
            client_id: applicantsOptions?.is_main_applicant ? applicantsOptions?.client_id ?? "" : "",
            derivates_id: !applicantsOptions?.is_main_applicant ? applicantsOptions?.derivates_id ?? "" : "",
        }
        dispatch(getQuestionnairesListAction(payload)).then(data => {
            let dataArray: any = []
            if (data.payload.statusCode == 200) {
                data.payload.data.forEach((el: any) => {
                    for (const key in el) {
                        const element = el[key];
                        dataArray.push({
                            id: Math.random(),
                            title: key.split(":")[0],
                            userName: key.split(":")[1],
                            content: element
                        })
                    }
                });
                setQuestionnaireData(dataArray);
            }
        })
    }

    const GetCasesForClient = () => {
        dispatch(getCaseOfClientBasedOnClientIDAction()).then(data => {
            if (data.payload.statusCode == 200) {
                let cases: any = []
                data.payload.data.forEach((element: { file_number: any; id: any; }) => {
                    cases.push({
                        label: element.file_number, value: element.id
                    });
                });
                setCaseOptionsData(cases);
                setCaseOptions(caseID ?? cases[0].id)
            }
        })
    }

    const GetApplicantBasedOnCaseForClient = (caseOptions: any) => {
        const payload = {
            case_id: caseOptions,
        }
        dispatch(getApplicantOfClientBasedOnClientIDAction(payload)).then(data => {
            if (data.payload.statusCode == 200) {
                let applicant: any = [{
                    label: "All Applicants", value: "all"
                }]
                data.payload.data.applicant_info.forEach((el: any) => {
                    applicant.push({
                        label: el.client_name, value: el
                    });
                });
                setApplicantData(applicant);
            }
        })
    }

    // Data Table Start
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderActionCustom
                controlId="questionnaires"
                rowData={rowData}
                isViewEnable={true}
                isCustomView={(e: any) => handleCustomEdit(rowData)}
            />
        );
    };

    const handleCustomEdit = (rowData: any) => {
        navigate(`${appConfig.basePath}/questionnaires/view/${caseOptions}/${rowData.questionnaire_id}`);
    };

    const statusColTemplate = (rowData: any) => {
        // Set color based on status
        let variantBg = '';
        switch (rowData.questionnaire_status_client) {
            case 'Completed':
                variantBg = 'bg-success';
                break;
            case 'In Progress':
                variantBg = 'bg-warning';
                break;
            case 'Pending':
                variantBg = 'theme-light-red-bg';
                break;
            default:
                variantBg = 'bg-primary';
        }
        return (
            <span
                className={`align-middle d-inline-block lh-sm min-w-100 p-1 rounded-1 text-center text-white ${variantBg}`}
            >
                {rowData.questionnaire_status_client}
            </span>
        )
    }

    // Table Columns
    const columns = [
        {
            field: "questionnaire_name",
            header: "Questionnaire",
        },
        {
            field: "questionnaire_description",
            header: "Description",
        },
        {
            field: "status",
            header: "Status",
            width: "300px",
            body: statusColTemplate,
            headerClassName: "theme-table-cell-center",
            bodyClassName: "text-center",
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell theme-table-cell-center",
            bodyClassName: "theme-action-btns-center",
            width: "100px",
            body: actionCellTemplate,
        },
    ];

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
                <div className="contentHeader p-1">
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap justify-content-between pb-2"
                    >
                        <h1 className="fw-semibold h4 mb-0">Questionnaires</h1>
                        <Breadcrumb className="fw-semibold">
                            <Breadcrumb.Item active>Questionnaires</Breadcrumb.Item>
                        </Breadcrumb>
                    </Stack>
                </div>
                <div className="flex-grow-1 pageContent position-relative pt-4 d-flex flex-column">
                    <Card className="bg-white shadow-lg border-0 theme-card-cover flex-grow-1">
                        <div className="theme-card-header px-1">
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                            >
                                <h5 className="mb-0 position-relative fw-normal me-auto">
                                    <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                        <span className="theme-icon-box-inner text-center">
                                            {SvgIcons.manageTeamIcon}
                                        </span>
                                    </div>
                                    Questionnaires assigned
                                </h5>
                                <Stack
                                    direction="horizontal"
                                    gap={3}
                                    className="flex-wrap ms-md-auto theme-filter-col-2"
                                >
                                    <div className="w-220">
                                        <ThemeCustomDropdown
                                            controlId="case_id"
                                            placeHolder="Case#"
                                            data={caseOptionsData}
                                            value={caseOptions}
                                            handleFilter={(e: any) => {
                                                setCaseOptions(e.value);
                                            }}

                                        />
                                    </div>
                                    <div className="w-220">
                                        <ThemeCustomDropdown
                                            controlId="all_applications"
                                            placeHolder="All Applicants"
                                            data={applicantData}
                                            value={applicantsOptions}
                                            handleFilter={(e: any) => {
                                                setApplicantsOptions(e.value);
                                            }}
                                            showClear={true}
                                        />
                                    </div>
                                </Stack>
                            </Stack>
                        </div>

                        <div className="px-20 pt-1 pb-4">
                            <div className="theme-accordion-cover">
                                <Accordion
                                    multiple
                                    activeIndex={allIndexes}
                                >
                                    {questionnaireData?.map((questionnaireTableData: any) => {
                                        return (
                                            <AccordionTab
                                                key={questionnaireTableData.questionnaire_id}
                                                headerTemplate={
                                                    <Stack
                                                        direction="horizontal"
                                                        gap={2}
                                                        className="flex-fill pe-3 mw-1"
                                                    >
                                                        <div className="d-inline-flex theme-accordion-title">
                                                            <h5 className="text-primary fw-bold mb-0 mw-100 pe-1 text-truncate">{questionnaireTableData?.title}: <span className="fw-normal text-capitalize">{questionnaireTableData?.userName?.toLowerCase()}</span></h5>
                                                        </div>
                                                    </Stack>
                                                }
                                            >
                                                <div className="theme-accordion-data">
                                                    <DataGridCommon
                                                        columns={columns}
                                                        data={questionnaireTableData?.content}
                                                        HideTablePaginator={HIDE_TABLE_PAGINATOR}
                                                    />
                                                </div>
                                            </AccordionTab>
                                        )
                                    })}
                                </Accordion>
                                {questionnaireData.length === 0 ? 'No questionnaire found' : ''}
                            </div>

                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ListQuestionnaires